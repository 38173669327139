<script context="module" lang="ts">
  export const load: Load = async ({ error, status, session }) => {
    if (error) {
      console.error(error);
    }
    if (error && status) {
      if (session.MAINTENANCE_MODE) status = 503;
      const htmlErrorMessage = htmlErrorMessages[status];
      if (htmlErrorMessage)
        return {
          props: {
            error,
            ...htmlErrorMessage,
            MAINTENANCE_MESSAGE: session.MAINTENANCE_MESSAGE,
            MAINTENANCE_MODE: session.MAINTENANCE_MODE,
          },
        };
    }
    return {
      props: {
        ...htmlErrorMessages[500]!,
        MAINTENANCE_MODE: false,
      },
    };
  };
</script>

<script lang="ts">
  import '../app.postcss';
  import { htmlErrorMessages } from '$lib/error-pages/error-codes';
  import PageTitle from '$lib/teach/resources/PageTitle.svelte';
  import { highContrastStore } from '$lib/stores/highContrastTheme.store';
  import ErrorPageHero from '$lib/error-pages/ErrorPageHero.svelte';
  import type { Load } from '@sveltejs/kit';

  export let message: string;
  export let code: number;
  export let title: string;
  export let reason: string;
  export let error: Error | undefined;
  export let MAINTENANCE_MESSAGE: string | undefined;
  export let MAINTENANCE_MODE: string | undefined;

  const year = new Date().getFullYear();

  let highContrast = false;
  highContrastStore.subscribe((bool) => {
    highContrast = bool;
  });
</script>

<PageTitle
  hideTitle
  title="{code} {reason}"
  description="{message}{error?.message ? error.message : ''}"
/>

<div class:high-contrast={highContrast}>
  <div style="position: relative">
    <div class="bg-blue wrapper pt-4">
      <header class="">
        <!-- Navigation: Big Screens -->
        <div class="margins md:flex justify-between hidden">
          <div class="flex-initial mt-2">
            <a href="/"
              ><img
                class="inline-block h-14"
                src="/teach/t-landing/logo-utheory-navigation-green.svg"
                alt="uTheory Logo"
                aria-hidden="true"
              /><span class="sr-only">uTheory Homepage</span></a
            >
          </div>
          <div id="nav" class="text-right relative" style="padding-top: 10px">
            <a
              rel="external"
              href="https://info.utheory.com"
              class="text-white font-semibold underline mt-3 pr-3 mr-2 lg:mr-10">Read the Blog ></a
            >
            {#if code === 503}
              <a
                rel="external"
                href="https://info.utheory.com/about"
                class="text-white font-semibold underline mt-3 pr-3 mr-2 lg:mr-10"
                >About uTheory ></a
              >
            {:else}
              <a href="/" class="text-white font-semibold underline mt-3 pr-3 mr-2 lg:mr-10"
                >uTheory Homepage ></a
              >
            {/if}
          </div>
        </div>
        <!-- /Navigation: Big Screens -->

        <!-- /Navigation: Small Screens -->
        <div class="margins block md:hidden">
          <div id="nav" class="flex justify-between relative">
            <a
              rel="external"
              href="https://info.utheory.com/"
              class="text-white font-semibold underline text-xs xs:text-base py-4"
              >Read the Blog ></a
            >
            {#if code === 503}
              <a
                rel="external"
                href="https://info.utheory.com/about"
                class="text-white font-semibold underline text-xs xs:text-base py-4"
                >About uTheory ></a
              >
            {:else}
              <a
                rel="external"
                href="https://info.utheory.com/about"
                class="text-white font-semibold underline text-xs xs:text-base py-4"
                >uTheory Homepage ></a
              >
            {/if}
          </div>
          <hr class="mt-3 mb-5" />
          <div class="margins flex">
            <div class="flex-initial">
              <a href="/"
                ><img
                  class="inline-block w-24"
                  src="/teach/t-landing/logo-utheory-navigation-green.svg"
                  alt="uTheory Logo"
                  aria-hidden="true"
                /></a
              >
            </div>
          </div>
        </div>
      </header>
      <!-- /Navigation: Small Screens -->

      <!-- Hero -->
      <div class="flex margins text-white mt-5 md:mt-14  flex-wrap md:flex-row md:flex-nowrap">
        <div class="text-center md:text-left pt-10 w-full md:w-1/2">
          <h1 class="pl-2 text-3xl md:text-4xl lg:text-5xl font-medium bg-red-50 text-red-700">
            {title}
          </h1>
          <p class="text-xl lg:text-2xl pt-3 mb-6 px-6 md:px-0 md:pr-4 font-light">
            {message}
          </p>
          {#if code === 503 && MAINTENANCE_MESSAGE}
            <p class="text-xl lg:text-2xl pt-3 mb-6 px-6 md:px-0 md:pr-4 font-light">
              {MAINTENANCE_MESSAGE}
            </p>
          {/if}

          {#if code !== 503}
            <a class="btn {!highContrast ? 'bg-cyan' : 'bg-gray'} mt-6 py-3 px-7 text-base" href="/"
              >Go Home</a
            >
          {/if}
        </div>
        <div class="mt-12 w-full md:w-1/2 px-8 md:mt-0 md:px-0 md:pl-14 mx-auto">
          <ErrorPageHero {code} />
        </div>
      </div>

      {#if error?.message && code !== 404 && !MAINTENANCE_MODE}
        <div class="margins text-white my-5 overflow-auto">
          <p class="text-xl lg:text-2xl pt-3 mb-6 px-6 md:px-0 md:pr-4 font-light">
            The server gave us this additional information:
          </p>
          <p class="text-xl lg:text-2xl pt-3 mb-6 px-6 md:px-0 md:pr-4 font-light ml-6">
            {error?.message || ''}
          </p>
        </div>
      {/if}

      <div class="margins text-white text-center py-20">
        <img
          src="/teach/t-landing/logo-utheory-green-square.jpg"
          alt="uTheory Logo"
          class="mx-auto"
        />
        <p class="max-w-lg mx-auto mt-4 text-xl xs:text-2xl">
          uTheory does it all: planning, teaching, assessing and grading so your students can learn,
          practice and master music theory
        </p>
      </div>

      <!-- End Footer -->
    </div>
    <!-- End Closing Tag / uTheory Does it All & Footer -->

    <!-- Copyright & TM -->
    <div class="text-center text-gray-light mx-auto text-base mt-4">
      &copy;{year} uTheory, LLP<br />
      uTheory is a registered trademark of uTheory, LLP
    </div>
    <!-- Copyright & TM -->
  </div>
</div>

<style lang="postcss">
  @import 'https://fonts.googleapis.com/css?family=Roboto:400,300,700,500';

  :global(body) {
    letter-spacing: 0 1em;
  }
</style>
