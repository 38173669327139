<script lang="ts">
  import { red } from 'design/src/tokens/colors';
  export let code: number;

  interface Layout {
    image: string;
    alt: string;
    errorCodeColor: string;
    video: {
      top: number;
      left: number;
      width: number;
      blur: number;
      brightness: number;
    };
    svg: {
      top: number;
      left: number;
      width: number;
      blur: number;
    };
  }

  const layoutsByErrorCode: { [key: string | number]: Layout } = {
    default: {
      image: '/img/error-page-images/maintenance-mode-hero-transparent-screen.svg',
      alt: `A person pushing a gear up a staircase of books, in front of a video screen
        showing a faded background of uTheory, and the number ${code} in large red letters
        in front.`,
      errorCodeColor: red[200],
      video: {
        top: 33,
        left: 29,
        width: 56,
        blur: 1,
        brightness: 25
      },
      svg: {
        top: 32,
        left: 40,
        width: 51,
        blur: 1
      }
    },
    '404': {
      image: '/img/error-page-images/404-hero-transparent-screen.svg',
      alt: `A person pushing a gear up a staircase of books, in front of a video screen
        showing a faded background of uTheory, and the number ${code} in large red letters
        in front.`,
      errorCodeColor: red[400],
      video: {
        top: 33,
        left: 21,
        width: 56,
        blur: 1,
        brightness: 20
      },
      svg: {
        top: 33,
        left: 21,
        width: 51,
        blur: 1
      }
    }
  };

  const layout = layoutsByErrorCode[code] || layoutsByErrorCode.default!;
</script>

<div class="relative mx-auto" style="max-width: 453px;">
  <svg
    viewBox="0 0 1920 1080"
    xmlns="http://www.w3.org/2000/svg"
    class="absolute z-10"
    style="
                top: {layout.svg.top}%;
                left: {layout.svg.left}%;
                width: {layout.svg.width}%;
                transform: skew(-12deg, 0deg);
                filter: blur({layout.svg.blur}px)
              "
  >
    <style>
      .error-code {
        font-size: 630pt;
      }
    </style>

    <!-- <rect x="0" y="0" width="1920" height="1080" /> -->
    <text
      x="200"
      y="800"
      style="fill: {layout.errorCodeColor}"
      class="error-code text-red-100 fill-current">{code}</text
    >
  </svg>
  <img class="relative z-20" src={layout.image} alt={layout.alt} />

  <!-- svelte-ignore a11y-media-has-caption -->
  <video
    src="/teach/t-landing/videos/hero-video-2.mp4"
    autoplay
    preload="auto"
    loop
    muted
    playsinline
    alt="Images from the uTheory app"
    class="absolute z-0 transform-gpu -skew-x-12"
    style="
        top:{layout.video.top}%; 
        left:{layout.video.left}%; 
        width:{layout.video.width}%; 
        --tw-skew-x: -10deg; 
        filter: brightness(.{layout.video.brightness}) 
        blur({layout.video.blur}px)
      "
  />
</div>
