export interface HTTPErrorMessage {
  /**
   * A long, human readable message such as, "Sorry, we were unable to find that
   * page on the server. It may have moved, or been removed from the site."
   */
  message: string;
  /**
   * A human-readable form of the error name, e.g. "Not Found"
   */
  reason: string;
  /**
   * The HTML error code number, e.g. 404
   */
  code: number;
  /**
   * The non-human-readable code name, e.g. NOT_FOUND
   */
  name: string;
  /**
   * A human-readable h1 title to pair with the .message. E.G., "Hmmm... where did we put that?"
   */
  title: string;
}

export const htmlErrorMessages: {
  [key: string | number]: HTTPErrorMessage;
} = {
  '301': {
    message:
      "The url of that page has been moved permanently. We're sorry we don't have a new link for you at this time.",
    reason: 'Moved Permanently',
    code: 301,
    name: 'MOVED_PERMANENTLY',
    title: 'Moving on up...',
  },
  '302': {
    message:
      "The url of that page has been moved temporarily. We're sorry we don't have a new link for you at this time.",
    reason: 'Moved Temporarily',
    code: 302,
    name: 'MOVED_TEMPORARILY',
    title: 'Moving on up...',
  },
  '303': {
    title: 'Ooops! Try again later...',
    message:
      'This page is temporarily unavailable. Usually this problem resolves within a few minutes.',
    reason: 'Temporarily Unavailable',
    code: 303,
    name: 'SEE_OTHER',
  },
  '304': {
    title: 'Not Modified',
    message:
      'The request attempted to modify a resource, but we were unable to change that resource.' +
      'We have no further info at this time.',
    reason: 'Not Modified',
    code: 304,
    name: 'NOT_MODIFIED',
  },
  '305': {
    title: 'Use a proxy',
    message: 'That page is only available by using a proxy.',
    reason: 'Use Proxy',
    code: 305,
    name: 'USE_PROXY',
  },
  '307': {
    title: 'Away from Our Desk...',
    message:
      'That page has been temporarily moved. You should have been redirected to ' +
      "a new url. We're sorry that didn't work out, please try again later.",
    reason: 'Temporary Redirect',
    code: 307,
    name: 'TEMPORARY_REDIRECT',
  },
  '308': {
    title: "We've moved!",
    message:
      'You should have been redirected to a new url for that page, which has been ' +
      "permanently moved. We're sorry that didn't work out, please try again later " +
      'or try finding the resource using the links on this page.',
    reason: 'Permanent Redirect',
    code: 308,
    name: 'PERMANENT_REDIRECT',
  },
  '400': {
    title: 'Uh-oh!',
    message: "The server rejected your request for being invalid. We're sorry we don't know more.",
    reason: 'Bad Request',
    code: 400,
    name: 'BAD_REQUEST',
  },
  '401': {
    title: 'ID, please?',
    message: 'You must be logged in to view that page.',
    reason: 'Unauthorized',
    code: 401,
    name: 'UNAUTHORIZED',
  },
  '402': {
    title: 'Sorry',
    message: 'Payment is required to access that page.',
    reason: 'Payment Required',
    code: 402,
    name: 'PAYMENT_REQUIRED',
  },
  '403': {
    title: 'Wait a minute!',
    message:
      'The server believes that you are not allowed to access that page. ' +
      'You might need to sign in first, or sign in as a different user. ' +
      'If you think you should have access, contact us at support@utheory.com.',
    reason: 'Forbidden',
    code: 403,
    name: 'FORBIDDEN',
  },
  '404': {
    title: 'Ooops!',
    message:
      "We can't find that page. Maybe there's a typo in the address. Or maybe we " +
      'moved or deleted the page. If you think it should exist, contact us ' +
      'at support@utheory.com.',
    reason: 'Not Found',
    code: 404,
    name: 'NOT_FOUND',
  },
  '405': {
    title: 'Method not allowed',
    message: 'The method used (e.g., GET, PUT, POST, DELETE) to access that page is not allowed.',
    reason: 'Method Not Allowed',
    code: 405,
    name: 'METHOD_NOT_ALLOWED',
  },
  '406': {
    title: 'Translation, please?',
    message:
      "We couldn't provide that resource in one of the formats your request specified " +
      'in its list of headers. (E.g., you asked for XML, but we only provide JSON.)',
    reason: 'Not Acceptable',
    code: 406,
    name: 'NOT_ACCEPTABLE',
  },
  '407': {
    title: 'Use a proxy',
    message: 'That page is only available by using a proxy.',
    reason: 'Proxy Authentication Required',
    code: 407,
    name: 'PROXY_AUTHENTICATION_REQUIRED',
  },
  '408': {
    title: 'Out to lunch...',
    message:
      'Sorry, your request took way longer than expected, so we gave up ' +
      'trying to process it. Our server may be overloaded, or there may be ' +
      "another error. If the problem doesn't resolve, contact us at support@utheory.com",
    reason: 'Request Timeout',
    code: 408,
    name: 'REQUEST_TIMEOUT',
  },
  '409': {
    title: 'How do we reconcile...',
    message:
      'The request was in conflict with the current state of the target resource. ' +
      'This error may resolve itself in a few moments. If not, please contact us at ' +
      'support@utheory.com',
    reason: 'Conflict',
    code: 409,
    name: 'CONFLICT',
  },
  '410': {
    title: 'Gone for good!',
    message: "That resource no longer exists, so we couldn't fulfill the request.",
    reason: 'Gone',
    code: 410,
    name: 'GONE',
  },
  '411': {
    title: 'Do you want the TL;DR; or the full deets?',
    message: 'Please specify a Content-Length header in your request for that resource.',
    reason: 'Length Required',
    code: 411,
    name: 'LENGTH_REQUIRED',
  },
  '412': {
    title: 'Wish we could help...',
    message:
      'We cannot meet the preconditions specified in your request header ' +
      "fields, so instead we're showing you this message.",
    reason: 'Precondition Failed',
    code: 412,
    name: 'PRECONDITION_FAILED',
  },
  '413': {
    title: 'Too big!',
    message:
      'The message or request you tried to send to the server was too large ' +
      ' for us to handle. Try again, but smaller!',
    reason: 'Request Too Long',
    code: 413,
    name: 'REQUEST_TOO_LONG',
  },
  '414': {
    title: 'Too long!',
    message: 'The url was too long for us to handle. Try again, but shorter!',
    reason: 'URI Too Long',
    code: 414,
    name: 'REQUEST_URI_TOO_LONG',
  },
  '415': {
    title: "What's an LP?",
    message: "Sorry, we don't support the media type you specified in you're request.",
    reason: 'Unsupported Media Type',
    code: 415,
    name: 'UNSUPPORTED_MEDIA_TYPE',
  },
  '416': {
    title: 'Out of bounds',
    message: "Sorry, we couldn't satisfy the range requested.",
    reason: 'Range Not Satisfiable',
    code: 416,
    name: 'REQUESTED_RANGE_NOT_SATISFIABLE',
  },
  '417': {
    title: 'Not living up to your expectations...',
    message: "You're request specified an Expect header which we were unable to satisfy.",
    reason: 'Expectation Failed',
    code: 417,
    name: 'EXPECTATION_FAILED',
  },
  '418': {
    title: "I'm a little teapot...",
    message:
      "La do' sol. Fa fa sol mi mi, re re mi do. Do re mi fa sol do' la do' sol..." +
      "DO' la sol sol fa mi re do.",
    reason: "I'm a Teapot",
    code: 418,
    name: 'IM_A_TEAPOT',
  },
  '421': {
    title: 'Hmmm, wrong server?',
    message:
      "The domain and scheme (http/https) combination you requested can't be " +
      'provided by this server.',
    reason: 'Misdirected Request',
    code: 421,
    name: 'MISDIRECTED_REQUEST',
  },
  '422': {
    title: "I'm sorry Dave...",
    message:
      "...I'm afraid I can't do that. We understood the request and format " +
      "but can't process the contained instructions. Please modify the request before " +
      'trying again',
    reason: 'Unprocessable Entity',
    code: 422,
    name: 'UNPROCESSABLE_ENTITY',
  },
  '423': {
    title: 'Locked!',
    message:
      "The resource you're requesting is currently under lock and key. This " +
      "may be because it's being modified by someone else at the moment. You should " +
      'try your request again in a bit.',
    reason: 'Locked',
    code: 423,
    name: 'LOCKED',
  },
  '424': {
    title: 'Prior error...',
    message: 'This request failed due to failure of a previous request',
    reason: 'Failed Dependency',
    code: 424,
    name: 'FAILED_DEPENDENCY',
  },
  '425': {
    title: 'Too early!',
    message:
      "We're not morning people, apparently. The request was rejected because " +
      'it came before the server was willing to handle it.',
    reason: 'Too Early',
    code: 425,
    name: 'TOO_EARLY',
  },
  '426': {
    title: 'Upgrade required',
    message: 'We only accept requests using https, not http. Please try again using https.',
    reason: 'Upgrade Required',
    code: 426,
    name: 'UPGRADE_REQUIRED',
  },
  '428': {
    title: 'Precondition required',
    message: 'An if-match header is missing from your request. Try again with the correct header',
    reason: 'Precondition Required',
    code: 428,
    name: 'PRECONDITION_REQUIRED',
  },
  '429': {
    title: 'Slow down!',
    message:
      'You are being rate-limited for making too many requests too quickly. Please ' +
      'try again later. But meno mosso, if you please!',
    reason: 'Too Many Requests',
    code: 429,
    name: 'TOO_MANY_REQUESTS',
  },
  '431': {
    title: 'Too big!',
    message:
      'The message headers you tried to send to the server were too large ' +
      ' for us to handle. Try again, but smaller!',
    reason: 'Request Header Fields Too Large',
    code: 431,
    name: 'REQUEST_HEADER_FIELDS_TOO_LARGE',
  },
  '451': {
    title: 'I fought the law...',
    message:
      '...but the law won. This resource has been made unavailable at the ' +
      'request of legal or law enforcement authorities',
    reason: 'Unavailable For Legal Reasons',
    code: 451,
    name: 'UNAVAILABLE_FOR_LEGAL_REASONS',
  },
  '497': {
    title: 'Upgrade required',
    message:
      'We only accept requests using https, not http at that endpoint. Please try again using https.',
    reason: 'Request sent to HTTPS port',
    code: 497,
    name: 'REQUEST_SENT_TO_HTTPS_PORT',
  },
  '499': {
    title: 'Give us a little more time?',
    message:
      'Your browser/client closed the request before we could fulfill it. Please ' +
      'give us a bit more time before giving up.',
    code: 499,
    name: 'CLIENT_CLOSED_REQUEST',
    reason: 'Client Closed Request',
  },
  '500': {
    title: 'Ooops!',
    message:
      "Something went wrong on our server, and we weren't able to process " +
      'your request. If this continues, please contact support@utheory.com',
    reason: 'Internal Server Error',
    code: 500,
    name: 'INTERNAL_SERVER_ERROR',
  },
  '501': {
    title: 'Coming soon...?',
    message: 'The method you requested is not yet implemented.',
    reason: 'Not Implemented',
    code: 501,
    name: 'NOT_IMPLEMENTED',
  },
  '502': {
    title: "Couldn't pass it on...",
    message:
      'When the server tried to pass on your message to another server ' +
      "to respond, that server returned a bad response, or didn't respond at all. " +
      'If this continues, please contact us at support@utheory.com.',
    reason: 'Bad Gateway',
    code: 502,
    name: 'BAD_GATEWAY',
  },
  '503': {
    title: "We'll be right back...",
    message:
      "We've gone offline temporarily to make some upgrades to the site. " +
      "We're sorry for the inconvenience.",
    reason: 'Service Unavailable',
    code: 503,
    name: 'SERVICE_UNAVAILABLE',
  },
  '504': {
    title: "Couldn't pass it on...",
    message:
      'When the server tried to pass on your message to another server ' +
      'to respond, that server took to long to respond. ' +
      'If this continues, please contact us at support@utheory.com.',
    reason: 'Gateway Timeout',
    code: 504,
    name: 'GATEWAY_TIMEOUT',
  },
  '505': {
    title: 'Not supported',
    message: "This server doesn't support that HTTP version.",
    reason: 'HTTP Version Not Supported',
    code: 505,
    name: 'HTTP_VERSION_NOT_SUPPORTED',
  },
  '506': {
    title: 'Circular negotiation...',
    message: 'Transparent content negotiation for hthe request results in a circular reference.',
    reason: 'Variant Also Negotiates',
    code: 506,
    name: 'VARIANT_ALSO_NEGOTIATES',
  },
  '507': {
    title: 'Out of space!',
    message:
      "We couldn't complete your request because we're out of space! If the problem " +
      'continues, please contact us at support@utheory.com',
    reason: 'Insufficient Storage',
    code: 507,
    name: 'INSUFFICIENT_STORAGE',
  },
  '508': {
    title: "I'm going in circles...",
    message:
      'We ran into a loop while trying to fulfill your request. If the problem ' +
      'continues, please contact us at support@utheory.com',
    reason: 'Loop Detected',
    code: 508,
    name: 'LOOP_DETECTED',
  },
  '509': {
    title: 'We need a bigger pipe...',
    message: "We couldn't fulfill your request becuase it required too much bandwidth",
    reason: 'Bandwidth Limit Exceeded',
    code: 509,
    name: 'BANDWIDTH_LIMIT_EXCEEDED',
  },
  '510': {
    title: 'Missing extension',
    message:
      'Your request asked us to use an extension, via the HTTP Extension Framework ' +
      'but this server does not support that extension. Sorry.',
    reason: 'Not Extended',
    code: 510,
    name: 'NOT_EXTENDED',
  },
  '511': {
    title: 'WiFi Password Required',
    message: 'You must authenticate to your local network before making this request.',
    reason: 'Network Authentication Required',
    code: 511,
    name: 'NETWORK_AUTHENTICATION_REQUIRED',
  },
};
